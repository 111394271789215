import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import '../assets/styles/pages/page-not-found.scss';

const NotFoundPage = () => (
  <Layout hideHeaderFooter={true}>
    <SEO title="DingGo" description="DingGo | Free quotes from local car body shops using just a few photos of your damage. Car ding? DingGo!" />
    <div className="container page-not-found-wrapper">
      <div className="row">
        <div className="col-6">
          <div className="e-1-1 h1">Sorry!</div>
          <div className="e-1-2 h2">Our office DingGo just ran off with that page.</div>
          <div className="e-1-3 h3">Here are some helpful links.</div>

          <a href="https://www.dinggo.com.au/" className="orange-link text-o sm-link">Home</a><br />

          <a href="https://www.dinggo.com.au/quote" className="orange-link text-o sm-link">Get Started</a><br />

          <a href="https://www.dinggo.com.au/faq" className="orange-link text-o sm-link">FAQ's</a><br />

          <a href="https://www.dinggo.com.au/whyjoin" className="orange-link text-o sm-link"> For Panel Beaters</a>

        </div>
        <div className="col-6">
          <div className="e-2-1 ah h1">404</div>
          <div className="e-2-2 ah h2">Not Found</div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
